/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './authentication.interceptor';
import { EncodeDataInterceptor } from './encode-data.interceptor';
import { HttpErrorHandlingInterceptor } from './http-error-handling.interceptor';
import { SmartTableLoadingInterceptor } from './smart-table-loading.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SmartTableLoadingInterceptor,
    multi: true
  },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: EncodeDataInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorHandlingInterceptor,
    multi: true
  }
];
