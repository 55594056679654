import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HttpError } from '../models/http-error.model';
import { environment } from 'src/environments/environment';
import * as Cookie from 'js-cookie';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, public router: Router) {}

  public request(
    method: string,
    url: string,
    data: any = null,
    contentType = 'application/json'
  ): any {
    let headers = new HttpHeaders({
      'Content-Type': contentType
    });
    if (contentType === 'multipart/form-data') {
      headers = headers.delete('Content-Type');
    }
    console.log('this.http[method]', method);
    console.log('data', data);
    if (data) {
      if (method === 'post') {
        return this.http.post(`${this.baseUrl}${url}`, data, {
          headers
        });
      }
      if (method === 'put') {
        return this.http.put(`${this.baseUrl}${url}`, data, {
          headers
        });
      }
    }
    if (method === 'get') {
      return this.http.get(`${this.baseUrl}${url}`, {
        headers,
        withCredentials: true
      });
    }
    if (method === 'getBlob') {
      return this.http.get(`${this.baseUrl}${url}`, {
        headers,
        responseType: 'blob' as 'json',
        withCredentials: true
      });
    }
    if (method === 'delete') {
      return this.http.delete(`${this.baseUrl}${url}`, {
        headers,
        withCredentials: true
      });
    }

    console.log('this.http[method]', method);

    // return this.http[method](`${this.baseUrl}${url}`, {
    //   headers,
    //   withCredentials: true
    // }).pipe(catchError(this.authentication.bind(this)));
  }

  authentication(error: HttpError): Observable<Error> {
    if (error.status === 401) {
      localStorage.clear();
      Cookie.remove('token');
      this.router.navigateByUrl('/sign-in');
    }
    return throwError(error);
  }
}
