import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { DateFormatPipe } from 'src/app/shared/pipes/jdate.pipe';
import { ServerDataSource } from 'ng2-smart-table';
import { HttpClient } from '@angular/common/http';
import { SmartTableService } from 'src/app/shared/service/smart-table.service';
import { User } from 'src/app/shared/models/user.model';
import { ImageModalComponent } from 'src/app/shared/components/image-modal/image-modal.component';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserService } from 'src/app/shared/service/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  sub = new SubSink();
  tableLoading = true;
  settings: any;
  source!: ServerDataSource;
  recordPerPage = 15;
  page!: number | undefined;
  cachedPage?: number;
  downloadUrl: string | undefined;
  token: string | undefined;

  constructor(
    private dateFormatPipe: DateFormatPipe,
    private http: HttpClient,
    private smartTableService: SmartTableService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {
    this.token = this.authenticationService.getToken();
  }

  initTable(): void {
    this.settings = {
      actions: false,
      hideSubHeader: true,
      sort: false,
      columns: {
        id: {
          title: 'شناسه',
          sort: false,
          filter: false
        },
        created_at: {
          title: 'تاریخ ثبت‌نام',
          valuePrepareFunction: (value: any, row: any) => {
            return this.dateFormatPipe.transform(value, 'jYYYY/jMM/jDD');
          },
          sort: false
        },
        first_name: {
          title: 'نام',
          valuePrepareFunction: (value: any) => {
            if (value !== null) {
              return value;
            }
            return '-';
          },
          sort: false
        },
        last_name: {
          title: 'نام خانوادگی',
          valuePrepareFunction: (value: any) => {
            if (value !== null) {
              return value;
            }
            return '-';
          },
          sort: false
        },
        phone: {
          title: 'شماره تلفن',
          valuePrepareFunction: (value: any) => {
            if (value !== null) {
              return value;
            }
            return '-';
          },
          sort: false
        },
        gender: {
          title: 'جنسیت',
          sort: false,
          valuePrepareFunction: (value: any) => {
            if (value === 'male') {
              return 'مرد';
            }
            if (value === 'female') {
              return 'زن';
            }
            return '-';
          },
          filter: {
            type: 'list',
            config: {
              selectText: 'همه',
              list: [
                { value: 'female', title: 'زن' },
                { value: 'male', title: 'مرد' }
              ]
            }
          }
        },
        national_id: {
          title: 'کد ملی',
          valuePrepareFunction: (value: any) => {
            if (value !== null) {
              return value;
            }
            return '-';
          },
          sort: false
        },
        birthday: {
          title: 'تاریخ تولد',
          sort: false,
          valuePrepareFunction: (value: any) => {
            if (value) {
              return this.dateFormatPipe.transform(value, 'jYYYY/jMM/jDD');
            }
            return '-';
          },
          filter: false
        },
        authenticated: {
          title: 'وضعیت احراز هویت',
          type: 'html',
          valuePrepareFunction: (value: any, row: any) => {
            switch (value) {
              case 'UNKNOWN':
                return `<span class='text-danger'><i class='fas fa-times ms-1 '></i> تکمیل نشده.</span>`;
                break;
              case 'ACCEPTED':
                return `<span class='text-success'><i class='fas fa-check ms-1 '></i> تکمیل شده.</span>`;
                break;
              case 'PENDING':
                return `<span class='text-warning'><i class='fas fa-times ms-1 '></i> در انتظار تایید.</span>`;
                break;

              case 'REJECTED':
                return `<span class='text-danger'><i class='fas fa-times ms-1 '></i> رد شده.</span>`;
                break;
              default:
                return `<span class='text-danger'><i class='fas fa-times ms-1 '></i> تکمیل نشده.</span>`;
                break;
            }
            if (value) {
              return `<span class='text-success'><i class='fas fa-check ms-1 '></i> تکمیل شده.</span>`;
            }
            return `<span class='text-danger'><i class='fas fa-times ms-1 '></i> تکمیل نشده.</span>`;
          },
          filter: {
            type: 'list',
            config: {
              selectText: 'همه',
              list: [
                { value: true, title: 'تکمیل شده' },
                { value: false, title: 'تکمیل نشده' }
              ]
            }
          }
        }
      },
      attr: {
        class: 'smart-table-responsive table-bordered text-center no-wrap'
      },
      pager: {
        display: true,
        perPage: 15
      },
      noDataMessage: 'کاربری یافت نشد.'
    };
    this.source = new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + '/v1/admin/user',
      dataKey: 'data',
      totalKey: 'total_records',
      pagerPageKey: 'page',
      pagerLimitKey: 'limit',
      filterFieldKey: '#field#',
      sortDirKey: 'order_direction',
      sortFieldKey: 'order_by'
    });
    this.sub.sink = this.source.onChanged().subscribe(() => {
      const paging = this.source.getPaging();
      this.downloadUrl = `${environment.baseUrl}/v1/admin/user?page=${paging.page}&limit=${paging.perPage}&token=${this.token}&export=1`;
      this.source.getFilter().filters.map((value: { field: string; search: string }) => {
        this.downloadUrl += `&${encodeURI(value.field)}=${encodeURI(value.search)}`;
      });
      this.tableLoading = false;
      this.smartTableService.makeTableResponsive();
      if (this.cachedPage) {
        this.changePage(this.cachedPage);
        this.cachedPage = undefined;
      }
    });
  }

  actions($event: any): void {
    if ($event.action === 'notification') {
      this.router.navigateByUrl('/dashboard/notification', {
        state: $event.data
      });
    } else {
      this.userService.currentUserPage = this.source.getPaging().page;
      this.router.navigateByUrl(`/dashboard/user/${$event.data.id}`);
    }
  }

  changePage(page: any): void {
    this.source.setPage(parseInt(page, 10));
    this.tableLoading = true;
  }


  onSearch(query: string = ''): void {
    this.source.setFilter(
      [
        // fields we want to include in the search
        {
          field: 'first_name',
          search: query
        },
        {
          field: 'last_name',
          search: query
        },
        {
          field: 'email',
          search: query
        },
        {
          field: 'phone',
          search: query
        },
        {
          field: 'national_id',
          search: query
        }
      ],
      false
    );
  }

  changeRecordPerPageLimit(recordPerPage: number): void {
    this.recordPerPage = recordPerPage;
    this.page = undefined;
    this.source.setPaging(1, recordPerPage, true);
    this.tableLoading = true;
  }

  ngOnInit(): void {
    this.initTable();
    this.sub.sink = this.userService.setUserListPage.subscribe((page) => {
      if (page !== 1) {
        this.userService.setUserListPage.next(1);
        this.cachedPage = page;
      }
    });
    this.sub.sink = this.smartTableService.loadingEvent.subscribe((page) => {
      if (page === 'user') {
        this.tableLoading = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
