export class Utility {
  static camelize(object: any): any {
    const keys = Object.keys(object);
    const values = Object.values(object);
    const newObject = {};
    for (let i = 0; i < keys.length; i++) {
      const newKey = keys[i].replace(/^([A-Z])|[\s-_]+(\w)/g, function (match, p1, p2, offset) {
        if (p2) {
          return p2.toUpperCase();
        }
        return p1.toLowerCase();
      });
      // @ts-ignore
      newObject[newKey] = values[i];
    }
    return newObject;
  }

  // tslint:disable-next-line:typedef
  static decamelize(object: any, separator = '_') {
    separator = typeof separator === 'undefined' ? '_' : separator;
    const keys = Object.keys(object);
    const values = Object.values(object);
    const newObject = {};
    for (let i = 0; i < keys.length; i++) {
      const newKey = keys[i]
        .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
        .toLowerCase();
      // @ts-ignore
      newObject[newKey] = values[i];
    }
    return newObject;
  }
}
