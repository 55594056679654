import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {
  faToEnDigits(input: string): any {
    if (input === undefined) {
      return;
    }
    let returnModel = '';
    const symbolMap = {
      '۱': '1',
      '۲': '2',
      '۳': '3',
      '۴': '4',
      '۵': '5',
      '۶': '6',
      '۷': '7',
      '۸': '8',
      '۹': '9',
      '۰': '0'
    };
    input = input.toString();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < input.length; i++) {
      // @ts-ignore
      if (symbolMap[input[i]]) {
        // @ts-ignore
        returnModel += symbolMap[input[i]];
      } else {
        returnModel += input[i];
      }
    }
    return returnModel;
  }

  enToFaDigits(input: string): any {
    if (input === undefined) {
      return;
    }
    let returnModel = '';
    // tslint:disable-next-line:prefer-const
    const symbolMap = {
      1: '۱',
      2: '۲',
      3: '۳',
      4: '۴',
      5: '۵',
      6: '۶',
      7: '۷',
      8: '۸',
      9: '۹',
      0: '۰'
    };
    input = input.toString();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < input.length; i++) {
      // @ts-ignore
      if (symbolMap[input[i]]) {
        // @ts-ignore
        returnModel += symbolMap[input[i]];
      } else {
        returnModel += input[i];
      }
    }
    return returnModel;
  }

  transform(value: any, args: 'toPersian' | 'toEnglish'): any {
    if (args === 'toEnglish') {
      return this.faToEnDigits(value);
    }
    if (args === 'toPersian') {
      return this.enToFaDigits(value);
    }
    return value;
  }

}
