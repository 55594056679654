<div class="w-100 d-flex">
  <div class="picker-part">
    <nb-select
      [status]="error ? 'danger' : 'basic'"
      class="right"
      (selectedChange)="checkDate($event, 'day')"
      [(selected)]="day"
      placeholder="روز"
    >
      <nb-option
        *ngFor="let day of days | fixMonthDays: month:year; let i = index"
        [disabled]="checkDisableDay(i + 1)"
        [value]="i + 1"
        class="faNum"
      >
        {{ i + 1 < 10 ? '0' + (i + 1) : i + 1 }}
      </nb-option>
    </nb-select>
  </div>
  <div class="picker-part">
    <nb-select
      [status]="error ? 'danger' : 'basic'"
      class="center"
      (selectedChange)="checkDate($event, 'month')"
      [(selected)]="month"
      placeholder="ماه"
    >
      <nb-option
        [disabled]="checkDisableMonth(i + 1)"
        [value]="i + 1"
        *ngFor="let month of months; let i = index"
      >
        {{ month }}
      </nb-option>
    </nb-select>
  </div>
  <div class="picker-part">
    <nb-select
      [status]="error ? 'danger' : 'basic'"
      class="left"
      (selectedChange)="checkDate($event, 'year')"
      [(selected)]="year"
      placeholder="سال"
    >
      <nb-option
        [disabled]="checkDisableYear(year)"
        [value]="year"
        class="faNum"
        *ngFor="let year of years; let i = index"
      >
        {{ year }}
      </nb-option>
    </nb-select>
  </div>
</div>
