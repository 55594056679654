import { Injectable } from '@angular/core';
import { DateModel } from 'src/app/shared/models/date.model';
import * as moment from 'moment-jalaali';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  constructor() {}

  today(date: DateModel): void {
    date.day = +moment().format('jDD');
    date.month = +moment().format('jMM');
    date.year = +moment().format('jYYYY');
  }

  todayStringGregorian(): string {
    return moment().format('YYYY-MM-DD');
  }

  previousWeek(): string {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return moment(oneWeekAgo).format('YYYY-MM-DD');
  }

  todayGregorian(): string {
    return moment().format('YYYY-MM-DD HH:mm:ss');
  }

  startOfDay(date: DateModel): void {
    date.hour = 0;
    date.minute = 0;
    date.second = 0;
  }

  endOfDay(date: DateModel): void {
    date.hour = 23;
    date.minute = 59;
    date.second = 59;
  }

  timeValidate(date: DateModel): boolean {
    return !(
      date.hour === null ||
      date.hour === undefined ||
      date.minute === null ||
      date.minute === undefined ||
      date.second === null ||
      date.second === undefined
    );
  }

  dateValidate(date: DateModel): boolean {
    return !!(date.year && date.month && date.day);
  }

  now(date: DateModel): void {
    date.hour = new Date().getHours();
    date.minute = new Date().getMinutes();
    date.second = new Date().getSeconds();
  }

  convertDateToString(date: DateModel, format = 'YYYY-MM-DD HH:mm:ss'): string | undefined {
    if (this.dateValidate(date) && this.timeValidate(date)) {
      return moment(
        `${date.year}-${date.month}-${date.day} ${date.hour}:${date.minute}:${date.second}`,
        'jYYYY-jMM-jDD HH:mm'
      ).format(format);
    } else if (this.dateValidate(date)) {
      return moment(`${date.year}-${date.month}-${date.day}`, 'jYYYY-jMM-jDD').format(format);
    }
    return undefined;
  }

  convertDateToStringGregorian(date: DateModel, format = 'YYYY-MM-DD'): string {
    return moment(
      `${date.year}-${date.month}-${date.day} ${date.hour}:${date.minute}:${date.second}`,
      'jYYYY-jMM-jDD'
    ).format(format);
  }

  convertStringDateToDate(date: string): DateModel {
    const dateModel = {} as DateModel;
    dateModel.year = +moment(date, 'YYYY-MM-DD HH:mm:ss').format('jYYYY');
    dateModel.month = +moment(date, 'YYYY-MM-DD HH:mm:ss').format('jMM');
    dateModel.day = +moment(date, 'YYYY-MM-DD HH:mm:ss').format('jDD');
    return dateModel;
  }

  convertGregorianToJalali(date: string): string {
    return moment(date, 'YYYY-MM-DD').format('jYYYY-jMM-jDD');
  }
}
