import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Utility } from './utility';
import { Observable } from 'rxjs';

@Injectable()
export class EncodeDataInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.body &&
      (req.headers.get('Content-Type') === 'application/x-www-form-urlencoded' ||
        req.headers.get('Content-Type') === 'application/json')
    ) {
      let data = req.body;
      if (Array.isArray(data)) {
        data = data.map((each) => Utility.decamelize(each));
      } else {
        data = Utility.decamelize(data);
      }
      let encodedData = data;
      if (req.headers.get('Content-Type') === 'application/x-www-form-urlencoded') {
        encodedData = Object.keys(data)
          .filter((k) => data.hasOwnProperty(k))
          .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
          .join('&');
      }
      req = req.clone({
        body: encodedData
      });
    }
    return next.handle(req);
  }
}
