<div class='d-flex align-items-center justify-content-between w-100'>
  <div class='d-flex align-items-center'>
    <nb-icon
      (click)='toggleSidebar()'
      icon='menu'
      style='font-size: 2rem; cursor: pointer'
    ></nb-icon>
    <div class='divider'></div>
    <div class='logo'>
     SVG Logo
     </div>
  </div>
  <div class='d-flex align-items-center'>
    <div class='user-menu d-flex align-items-center'>
      <img alt='avatar' src='assets/images/avatar.jpg' />
      <div class='user-info'>
        <p *ngIf='user.firstName && user.lastName' class='name'>{{ user.firstName + ' ' + user.lastName }}</p>
        <p class='identification text-success'>
          <i class='fas fa-check ms-1'></i>
          کاربر ادمین
        </p>
      </div>
    </div>
  </div>
</div>
