import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';
import {
  NbAlertModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTagModule
} from '@nebular/theme';
import { NumberLimitDirective } from 'src/app/shared/directives/number-limit.directive';
import { FormsModule } from '@angular/forms';
import { DateFormatPipe } from 'src/app/shared/pipes/jdate.pipe';
import { TimeAgoPipe } from 'src/app/shared/pipes/time-ago.pipe';
import { DatepickerComponent } from 'src/app/shared/components/datepicker/datepicker.component';
import { FixMonthDaysPipe } from 'src/app/shared/pipes/fix-month-days.pipe';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { UserLinkComponent } from 'src/app/shared/components/user-link/user-link.component';
import { RouterModule } from '@angular/router';
import { LimitDecimalNumberDirective } from 'src/app/shared/directives/limit-decimal-number.directive';
import { RangePickerComponent } from './components/range-picker/range-picker.component';
import { UserPickerComponent } from './components/user-picker/user-picker.component';
import { SelectCurrencyComponent } from 'src/app/shared/components/select-currency/select-currency.component';
import { EnabledCurrencyPipe } from './pipes/enabled-currency.pipe';
import {ModalComponent} from './components/modal/modal.component';

import {FormatNumberPipe} from './pipes/format-number.pipe';
import {FormatMoneyPipe} from './pipes/format-money.pipe';
import {FileComponent} from './components/file/file.component';


@NgModule({
  declarations: [
    ModalComponent,
    ValidationErrorComponent,
    NumberLimitDirective,
    DateFormatPipe,
    TimeAgoPipe,
    DatepickerComponent,
    FixMonthDaysPipe,
    OnlyNumberDirective,
    ImageModalComponent,
    UserLinkComponent,
    LimitDecimalNumberDirective,
    RangePickerComponent,
    UserPickerComponent,
    SelectCurrencyComponent,
    EnabledCurrencyPipe,
    FormatMoneyPipe,
    FormatNumberPipe,
    FileComponent,
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbButtonModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbIconModule,
    NbAlertModule,
    NbInputModule,
    NbSelectModule,
    FormsModule,
    NbDialogModule.forRoot(),
    RouterModule,
    NbSpinnerModule,
    NbAutocompleteModule,
    NbTagModule
  ],
  exports: [
    CommonModule,
    ValidationErrorComponent,
    NbCardModule,
    NbButtonModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbIconModule,
    NbAlertModule,
    NbInputModule,
    NumberLimitDirective,
    NbSelectModule,
    DateFormatPipe,
    TimeAgoPipe,
    DatepickerComponent,
    OnlyNumberDirective,
    ImageModalComponent,
    UserLinkComponent,
    LimitDecimalNumberDirective,
    RangePickerComponent,
    UserPickerComponent,
    SelectCurrencyComponent,
    ModalComponent,
    FormatMoneyPipe,
    FormatNumberPipe,
    FileComponent,
  ]
})
export class SharedModule {}
