import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateModel } from 'src/app/shared/models/date.model';
import { DateService } from 'src/app/shared/service/date.service';

@Component({
  selector: 'app-range-picker',
  templateUrl: './range-picker.component.html',
  styleUrls: ['./range-picker.component.scss']
})
export class RangePickerComponent implements OnInit {
  @Output() submit = new EventEmitter();
  @Input() errors = {};
  @Input() loading = false;

  startDate!: string;
  endDate!: string;
  jalaliStartDate!: DateModel;
  jalaliEndDate!: DateModel;

  constructor(private dateService: DateService) {}

  getDates(): void {
    this.submit.emit({
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  setStart(event: DateModel): void {
    this.startDate = this.dateService.convertDateToStringGregorian(event) ?? '';
  }

  setEnd(event: DateModel): void {
    this.endDate = this.dateService.convertDateToStringGregorian(event) ?? '';
  }

  ngOnInit(): void {
    this.endDate = this.dateService.todayStringGregorian();
    this.startDate = this.dateService.previousWeek();
    this.jalaliStartDate = this.dateService.convertStringDateToDate(this.startDate);
    this.jalaliEndDate = this.dateService.convertStringDateToDate(this.endDate);
  }
}
