import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from 'src/app/shared/service/user.service';
import { User } from 'src/app/shared/models/user.model';
import Cookies from 'js-cookie';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private http: HttpService, private userService: UserService) {}

  signIn(phone: string, password: string): Observable<any> {
    return this.http.request('post', '/v1/user/get-token', {
      phone,
      password,
    });
  }


  setToken(token: string, rememberMe: boolean): void {
    if (rememberMe) {
      localStorage.setItem('admin_token', token);
    } else {
      Cookies.set('admin_token', token);
    }
  }

  set userRole(role: string | undefined) {
    if (typeof role === 'string') {
      localStorage.setItem('role', role);
    }
  }

  signOut(): void {
    localStorage.removeItem('admin_token');
    localStorage.removeItem('role');
    Cookies.remove('admin_token');
  }

  isLoggedIn(): Observable<boolean> {
    return this.http.request('get', '/v1/user').pipe(
      map((response: any) => {
        this.setData(response.data);
        return true;
      }),
      catchError((val) => of(false))
    );
  }

  setData(data: any): void {
    this.userService.userStream.next(new User(data));
    localStorage.setItem('user', JSON.stringify(data));
  }

  getUser(): User | null {
    if (localStorage.getItem('user') !== null) {
      return new User(JSON.parse(localStorage.getItem('user') ?? ''));
    }
    return null;
  }

  getToken(): string | undefined {
    return localStorage.getItem('admin_token') || Cookies.get('admin_token') || undefined;
  }

  setImpersonateToken(token: string): void {
    Cookies.set('token', token, { domain: '.zararz.com' });
  }


  getSecretKey(): Observable<any> {
    return this.http.request('get', '/v1/user/google-auth').pipe(map((response: any) => response));
  }

  verifyTwoFactorCode(code: string): Observable<any> {
    return this.http.request('put', '/v1/user/google-auth', {
      code
    });
  }

  disableTwoFactor(password: string): Observable<any> {
    return this.http.request('delete', `/v1/user/google-auth?password=${password}`);
  }
}
