import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CurrencyService } from 'src/app/shared/service/currency.service';
import { Currency } from 'src/app/shared/models/currency.model';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-select-currency',
  templateUrl: './select-currency.component.html',
  styleUrls: ['./select-currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectCurrencyComponent implements OnInit, OnDestroy {
  @Output() currency: EventEmitter<Currency[]> = new EventEmitter<Currency[]>();
  @Input() multiple = false;
  @Input() selectAllExceptIRR = false;
  currencies!: Currency[];
  currencyReqSub!: Subscription;
  emptyCurrency = new Currency();
  selectedCurrencies: Currency[] = [];

  constructor(private currencyService: CurrencyService, private cf: ChangeDetectorRef) {}

  selectAll(): void {
    this.selectedCurrencies = this.currencies;
    this.currency.emit(this.selectedCurrencies);
    this.cf.markForCheck();
  }

  ngOnInit(): void {
    this.currencyReqSub = this.currencyService.getCurrencies().subscribe((currencies) => {
      this.currencies = currencies;
      for (const currency of this.currencies) {
        if (currency.id === 'BTC' && !this.multiple) {
          this.selectedCurrencies.push(currency);
          this.currency.emit(this.selectedCurrencies);
          this.cf.markForCheck();
        }
      }
      if (this.selectAllExceptIRR) {
        this.selectedCurrencies = this.currencies.filter((currency) => currency.id !== 'IRR');
        this.currency.emit(this.selectedCurrencies);
        this.cf.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this.currencyReqSub.unsubscribe();
  }
}
