<div class="modal fade" [id]="id" tabindex="-1" role="dialog" aria-labelledby="modal"
     aria-hidden="true" >
  <div class="modal-dialog modal-lg modal-dialog-centered" [style]="'max-width:' + maxWidth + 'px'" role="document">
    <div class="modal-content card card-gradient" [style]="'max-width:' + maxWidth + 'px'">
      <div class="modal-body position-relative">
        <a data-bs-dismiss="modal" id="dismissModal" class="close-icon">
          <svg aria-label="Close"
               data-name="Iconly/Bulk/Close Square"
               xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <path id="Fill_1" data-name="Fill 1"
                  d="M23.9,0H9.45C3.8,0,0,3.967,0,9.867V23.483c0,5.883,3.8,9.85,9.45,9.85H23.9c5.65,0,9.433-3.967,9.433-9.85V9.867C33.333,3.967,29.55,0,23.9,0"
                  transform="translate(3.333 3.333)" fill="#fff" opacity="0.4"/>
            <path id="Fill_4" data-name="Fill 4"
                  d="M10.483,8.42,7.518,5.456l2.963-2.963A1.458,1.458,0,0,0,8.42.43L5.455,3.393,2.49.426A1.459,1.459,0,0,0,.426,2.49L3.393,5.456.433,8.415a1.458,1.458,0,0,0,2.062,2.063l2.96-2.96,2.967,2.965A1.458,1.458,0,0,0,10.483,8.42"
                  transform="translate(14.543 14.531)" fill="#fff"/>
          </svg>
        </a>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
