import { Component, OnInit } from '@angular/core';
import { NbIconLibraries, NbThemeService } from '@nebular/theme';
import { UserService } from 'src/app/shared/service/user.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { WithdrawModel } from 'src/app/shared/models/withdraw.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: []
})
export class AppComponent implements OnInit {

  constructor(
    private iconLibraries: NbIconLibraries,
    private themeService: NbThemeService,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
    this.iconLibraries.registerFontPack('font-awesome-light', {
      packClass: 'fal',
      iconClassPrefix: 'fa'
    });
    this.iconLibraries.registerFontPack('font-awesome-brand', {
      packClass: 'fab',
      iconClassPrefix: 'fa'
    });
    this.iconLibraries.registerFontPack('font-awesome-regular', {
      packClass: 'far',
      iconClassPrefix: 'fa'
    });
    this.iconLibraries.registerFontPack('font-awesome-solid', {
      packClass: 'fas',
      iconClassPrefix: 'fa'
    });
  }

  ngOnInit(): void {
  }
}
