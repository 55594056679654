import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from 'src/app/shared/models/currency.model';

@Pipe({
  name: 'enabledCurrency'
})
export class EnabledCurrencyPipe implements PipeTransform {
  transform(currencies: Currency[]): Currency[] {
    return currencies?.filter((currency) => currency.isEnabled) ?? [];
  }
}
