import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { Observable } from 'rxjs';
import { Currency } from 'src/app/shared/models/currency.model';
import { map } from 'rxjs/operators';
import { CurrencyFaqModel } from 'src/app/shared/models/faq.model';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  constructor(private http: HttpService) {}

  getCurrencies(): Observable<Currency[]> {
    return this.http.request('get', `/v1/admin/currency`).pipe(
      map((response: any) => response.data),
      map((currencies: any[]) => currencies.map((currency) => new Currency(currency)))
    );
  }

  saveCurrency(currency: Currency): Observable<any> {
    const tempCurrency = Object.assign(new Currency(), currency);
    tempCurrency.rialToTooman();
    return this.http.request('put', `/v1/admin/currency/${currency.id}`, tempCurrency);
  }



}
