<ng-container *ngIf="multiple; else single">
  <div class="row">
    <div class="col-12 d-flex align-items-center">
      <nb-select
        class="w-100"
        fullWidth
        [multiple]="true"
        placeholder="انتخاب ارز"
        (selectedChange)="currency.next($event); this.selectedCurrencies = $event"
        [selected]="selectedCurrencies"
      >
        <nb-option *ngFor="let currency of currencies | enabledCurrency" [value]="currency">
          <div class="d-flex align-items-center">
            <img [src]="currency.iconPath" width="30" class="ms-2" alt="icon" />
            <span>{{ currency.name }} ({{ currency.id }})</span>
          </div>
        </nb-option>
      </nb-select>
    </div>
  </div>
</ng-container>
<ng-template #single>
  <div class="row">
    <div class="col-12">
      <nb-select
        class="w-100"
        fullWidth
        placeholder="انتخاب ارز"
        (selectedChange)="currency.next([$event])"
        [selected]="this.selectedCurrencies[0]"
      >
        <nb-option *ngFor="let currency of currencies | enabledCurrency" [value]="currency">
          <div class="d-flex align-items-center">
            <img [src]="currency.iconPath" width="30" class="ms-2" alt="icon" />
            <span>{{ currency.name }} ({{ currency.id }})</span>
          </div>
        </nb-option>
      </nb-select>
    </div>
  </div>
</ng-template>
