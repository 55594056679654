import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmartTableService {
  loadingEvent = new Subject<string>();

  constructor() {}

  makeTableResponsive(): void {
    if (document.getElementById('responsive-smart-table') === null) {
      const table = document.querySelector('ng2-smart-table table');
      const div = document.createElement('div');
      div.id = 'responsive-smart-table';
      div.className = 'table-responsive';
      document.querySelector('ng2-smart-table')?.prepend(div);
      if (table) {
        div.appendChild(table);
      }
    }
  }
}
