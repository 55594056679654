import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { NbMenuItem, NbSidebarService, NbThemeService } from '@nebular/theme';
import { SubSink } from 'subsink';
import { SidebarService } from 'src/app/shared/service/sidebar.service';
import { UserService } from 'src/app/shared/service/user.service';
import { User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('changeTheme', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'scale(0)'
        }),
        animate('300ms', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({
          opacity: 1,
          transform: 'scale(1)'
        }),
        animate('300ms', style({ opacity: 0, transform: 'scale(0)' }))
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentTheme: string;
  sub = new SubSink();
  resState!: 'pc' | 'tablet' | 'mobile';
  items: NbMenuItem[] = [
    {
      title: 'پروفایل',
      icon: {
        pack: 'font-awesome-light',
        icon: 'user'
      }
    },
    {
      title: 'خروج',
      icon: {
        pack: 'font-awesome-light',
        icon: 'sign-out'
      }
    }
  ];
  user: User = new User();
  isVerified: boolean = false;

  constructor(
    private themeService: NbThemeService,
    private sidebarService: SidebarService,
    private nbSidebarService: NbSidebarService,
    private userService: UserService
  ) {
    this.currentTheme = localStorage.getItem('theme') ?? 'dark';
  }

  changeTheme(theme: string): void {
    localStorage.setItem('theme', theme);
    this.currentTheme = theme;
    this.themeService.changeTheme(this.currentTheme);
  }

  toggleSidebar(): void {
    if (this.resState === undefined) {
      if (window.innerWidth < 576) {
        this.resState = 'pc';
      } else if (window.innerWidth < 992) {
        this.resState = 'tablet';
      } else {
        this.resState = 'pc';
      }
    }
    if (this.resState === 'pc' || this.resState === 'tablet') {
      this.nbSidebarService.toggle(true);
    }
    if (this.resState === 'mobile') {
      this.nbSidebarService.toggle();
    }
  }

  ngOnInit(): void {
    this.sub.sink = this.sidebarService.getResponsiveState.subscribe((resState) => {
      this.resState = resState;
    });
    this.sub.sink = this.userService.userStream.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
