<nb-layout>
  <nb-layout-column class='p-0'>
    <div class='container h-100'>
      <div class='row justify-content-center h-100 align-items-center'>
        <div class='col-12 col-xl-9'>
          <div class='custom-card'>
            <form (ngSubmit)='signIn()'>
              <div class='row m-0'>
                <div class='order-1 order-lg-0 col-12 col-lg-5 my-auto p-3'>
                  <div class='my-3 d-flex justify-content-center'>
                    <h3>پنل مدیریت زرارز</h3>
                  </div>
                  <div class='p-3'>
                    <nb-alert *ngIf='errorMessage !== undefined' outline='danger'>
                      {{ errorMessage }}
                    </nb-alert>
                    <section>
                      <div class='my-3'>
                        <nb-form-field>
                          <nb-icon nbSuffix icon='phone' pack='font-awesome-light'></nb-icon>
                          <input
                            [(ngModel)]='user.phone'
                            name='phone'
                            [status]="error['phone'] !== undefined ? 'danger' : 'basic'"
                            class='placeholder-right en-num'
                            appOnlyNumber
                            type='text'
                            dir='ltr'
                            fullWidth
                            placeholder='شماره تلفن'
                            nbInput
                          />
                        </nb-form-field>
                        <app-validation-error [errors]='error' name='phone'></app-validation-error>
                      </div>
                      <div class='my-3'>
                        <nb-form-field>
                          <input
                            autocomplete
                            [status]="error['password'] !== undefined ? 'danger' : 'basic'"
                            fullWidth
                            dir='ltr'
                            name='password'
                            class='placeholder-right en-num'
                            [(ngModel)]='user.password'
                            [type]='getInputType()'
                            placeholder='رمز عبور'
                            nbInput
                          />
                          <button
                            type='button'
                            nbSuffix
                            nbButton
                            ghost
                            (click)="toggleShowPassword('password')"
                          >
                            <nb-icon
                              [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                              pack='eva'
                              [attr.aria-label]="showPassword ? 'hide password' : 'show password'"
                            ></nb-icon>
                          </button>
                        </nb-form-field>
                        <app-validation-error
                          [errors]='error'
                          name='password'
                        ></app-validation-error>
                      </div>
                      <div class='my-3'>
                        <nb-checkbox [(checked)]='rememberMe'>مرا به خاطر بسپار</nb-checkbox>
                      </div>
                      <div class='d-flex justify-content-center my-3'>
                        <button
                          [nbSpinner]='signInLoading'
                          [disabled]='signInLoading'
                          type='submit'
                          fullWidth
                          nbButton
                          status='primary'
                          hero
                        >
                          ورود
                        </button>
                      </div>
                    </section>
                  </div>
                </div>
                <div class='order-0 order-lg-1 d-lg-flex col-12 col-lg-7 bg-sign-up'>
                  <img src='assets/images/login.png' alt='bg' />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
