<!--<nb-card>-->
<!--  <nb-card-header>-->
<!--    <div class="d-flex align-items-center">-->
<!--      <i class="far fa-chart-line ms-2"></i>-->
<!--      <span>گزارش‌گیری</span>-->
<!--    </div>-->
<!--  </nb-card-header>-->
<!--  <nb-card-body>-->
<!--    <div class="row mb-4">-->
<!--      <div class="col-12 col-lg-6">-->
<!--        <nb-card>-->
<!--          <nb-card-header>فقط کاربران لیست</nb-card-header>-->
<!--          <nb-card-body>-->
<!--            <app-user-picker-->
<!--              (add)="includedUsers = $event"-->
<!--              (remove)="includedUsers = $event"-->
<!--              [cacheKey]="'transaction_included_users'"-->
<!--            ></app-user-picker>-->
<!--          </nb-card-body>-->
<!--        </nb-card>-->
<!--      </div>-->
<!--      <div class="col-12 col-lg-6">-->
<!--        <nb-card>-->
<!--          <nb-card-header>به جز کاربران لیست</nb-card-header>-->
<!--          <nb-card-body>-->
<!--            <app-user-picker-->
<!--              (add)="excludedUsers = $event"-->
<!--              (remove)="excludedUsers = $event"-->
<!--              [cacheKey]="'transaction_excluded_users'"-->
<!--            ></app-user-picker>-->
<!--          </nb-card-body>-->
<!--        </nb-card>-->
<!--      </div>-->
<!--      <div class="col-12 col-lg-6 col-xxl-2">-->
<!--        <div class="row mb-3 align-items-center">-->
<!--          <label class="col-12 mb-2">نوع تراکنش</label>-->
<!--          <div class="col-12">-->
<!--            <nb-select-->
<!--              placeholder="نوع تراکنش"-->
<!--              fullWidth-->
<!--              id="type"-->
<!--              [(selected)]="type"-->
<!--              [status]="error['type'] === undefined ? 'basic' : 'danger'"-->
<!--            >-->
<!--              <nb-option [value]="'all'">همه</nb-option>-->
<!--              <nb-option [value]="'trade'">معامله</nb-option>-->
<!--              <nb-option [value]="'deposit'">واریز</nb-option>-->
<!--              <nb-option [value]="'withdraw'">برداشت</nb-option>-->
<!--            </nb-select>-->
<!--            <app-validation-error [errors]="error" name="type"></app-validation-error>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-12 col-lg-6 col-xxl-2">-->
<!--        <div class="row mb-3 align-items-center">-->
<!--          <label class="col-12 mb-2">بازار</label>-->
<!--          <div class="col-12">-->
<!--            <nb-select-->
<!--              placeholder="بازار"-->
<!--              fullWidth-->
<!--              id="market"-->
<!--              [(selected)]="market"-->
<!--              [status]="error['market'] === undefined ? 'basic' : 'danger'"-->
<!--            >-->
<!--              <nb-option [value]="'real'">واقعی</nb-option>-->
<!--              <nb-option [value]="'demo'">دمو</nb-option>-->
<!--            </nb-select>-->
<!--            <app-validation-error [errors]="error" name="type"></app-validation-error>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-12 col-lg-6 col-xxl-8">-->
<!--        <div class="d-flex mb-2">-->
<!--          ارز-->
<!--          <button size="tiny" class="me-3" (click)="currencySelector.selectAll()" nbSuffix nbButton>-->
<!--            انتخاب همه-->
<!--          </button>-->
<!--        </div>-->
<!--        <app-select-currency-->
<!--          [multiple]="true"-->
<!--          [selectAllExceptIRR]="true"-->
<!--          #currencySelector-->
<!--          (currency)="currencies = $event; this.filterTable()"-->
<!--        ></app-select-currency>-->
<!--        <app-validation-error [errors]="error" name="currency"></app-validation-error>-->
<!--      </div>-->
<!--    </div>-->
<!--    <app-range-picker (submit)="filterTable($event)"></app-range-picker>-->
<!--  </nb-card-body>-->
<!--</nb-card>-->
<nb-card>
  <nb-card-header>
    <div class="d-flex align-items-center">
      <i class="fal fa-money-check ms-2"></i>
      سفارشات کاربران
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="d-flex align-items-center mb-2">
      <nb-select
        class="ms-2"
        [selected]="15"
        (selectedChange)="changeRecordPerPageLimit($event)"
        placeholder="تعداد نمایش"
      >
        <nb-option [value]="10">10</nb-option>
        <nb-option [value]="15">15</nb-option>
        <nb-option [value]="25">25</nb-option>
        <nb-option [value]="50">50</nb-option>
        <nb-option [value]="100">100</nb-option>
      </nb-select>
    </div>
    <div class="position-relative">
      <ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>
      <div
        *ngIf="source.count() > recordPerPage"
        class="d-flex align-items-center pagination-input"
        dir="ltr"
      >
        <form class="d-flex" (ngSubmit)="changePage(page)">
          <button
            type="submit"
            class="go-to-page-button"
            nbButton
            status="primary"
            [disabled]="!page"
          >
            برو به
          </button>
          <input class="go-to-page-input" type="number" nbInput name="gotTo" [(ngModel)]="page" />
        </form>
      </div>
    </div>
    <div *ngIf="tableLoading" class="loading-backdrop">
      <i class="fal fa-circle-notch fa-spin fa-3x"></i>
    </div>
  </nb-card-body>
</nb-card>
