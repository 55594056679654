import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { User } from 'src/app/shared/models/user.model';
import { NbTagComponent } from '@nebular/theme';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss']
})
export class UserPickerComponent implements OnInit {
  @Input() cacheKey!: string;
  @Output() remove = new EventEmitter<User[]>();
  @Output() add = new EventEmitter<User[]>();

  selectedUsers: User[] = [];
  filterUsersByEmail$!: Observable<User[]>;
  filterUsersByName$!: Observable<User[]>;
  emailQuery!: string;
  nameQuery!: string;

  constructor(private userService: UserService) {}

  filter(query: any, type: string): void {
    const filter: any = {
      limit: 100
    };
    if (type === 'email' && typeof query === 'string') {
      filter.email = query;
      this.filterUsersByEmail$ = this.userService.getUserList(filter);
    }
    if (type === 'name' && typeof query === 'string') {
      const split = query.split(' ');
      filter.first_name = split[0];
      if (split[1]) {
        filter.last_name = split[1];
      }
      this.filterUsersByName$ = this.userService.getUserList(filter);
    }
  }

  viewHandleEmail(value: string | User): string {
    if (typeof value === 'string') {
      return value;
    }
    if (this.selectedUsers.find((user) => user.id === value.id) === undefined) {
      this.selectedUsers.push(value);
      this.cacheUsers();
    }
    this.add.emit(this.selectedUsers);
    return '';
  }

  cacheUsers(): void {
    if (this.cacheKey) {
      localStorage.setItem(this.cacheKey, JSON.stringify(this.selectedUsers));
    }
  }

  viewHandleName(value: string | User): string {
    if (typeof value === 'string') {
      return value;
    }
    if (this.selectedUsers.find((user) => user.id === value.id) === undefined) {
      this.selectedUsers.push(value);
      this.cacheUsers();
    }
    this.add.emit(this.selectedUsers);
    return '';
  }

  onTagRemove(tag: NbTagComponent): void {
    this.selectedUsers = this.selectedUsers.filter(
      (user) => user.id !== +tag._hostElement.nativeElement.getAttribute('data-delete')
    );
    this.remove.emit(this.selectedUsers);
    this.cacheUsers();
  }

  getCachedUsers(): void {
    if (this.cacheKey) {
      const rawUsers: any[] = JSON.parse(localStorage.getItem(this.cacheKey) ?? '[]');
      this.selectedUsers = rawUsers.map((user) => new User(user));
      this.add.emit(this.selectedUsers);
    }
  }

  ngOnInit(): void {
    this.filterUsersByEmail$ = of([]);
    this.filterUsersByName$ = of([]);
    this.getCachedUsers();
  }
}
