import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RootComponent } from './components/root/root.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { AuthenticationGuard } from 'src/app/core/guards/authentication.guard';
import { UsersComponent } from './components/users/users.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import {ReportModule} from './modules/report/report.module';

const routes: Routes = [
  {
    path: 'dashboard',
    component: RootComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'orders',
        component: TransactionsComponent
      },
      {
        path: 'identification',
        loadChildren: () =>
          import('./modules/identification/identification.module').then(
            (m) => m.IdentificationModule
          )
      },
      {
        path: 'forum',
        loadChildren: () =>
          import('./modules/forum/forum.module').then(
            (m) => m.ForumModule
          )
      },
      {
        path: 'faq',
        loadChildren: () =>
          import('./modules/faq/faq.module').then(
            (m) => m.FaqModule
          )
      },
      {
        path: 'report',
        loadChildren: () =>
          import('./modules/report/report.module').then(
            (m) => m.ReportModule
          )
      }
    ]
  },
  {
    path: 'sign-in',
    component: AuthenticationComponent,
    data: {
      action: 'sign-in'
    }
  },
  {
    path: 'two-factor-authentication',
    component: AuthenticationComponent,
    data: {
      action: 'two-factor-authentication'
    }
  },

  {
    path: '**',
    redirectTo: '/dashboard/users',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class FeatureRoutingModule {
}
