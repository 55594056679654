import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {FileService} from '../../service/file.service';


@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class FileComponent implements OnInit {
  @Input() label = 'انتخاب فایل';
  @Input() id = 'file';
  @Input() error = false;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onImageSelected = new EventEmitter<string>();

  dragOver = false;
  @Input() image!: string;

  constructor(private fileService: FileService) {
  }

  ngOnInit(): void {
  }

  getFile(evt: any): void {
    const file = evt.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (fileReader: any) => {
        const image = new Image();
        image.onload = () => {
          const canvas = this.fileService.compressImage(image);
          this.image = canvas.toDataURL('image/jpeg', 0.7);
          this.onImageSelected.emit(this.image);
        };
        image.src =
          'data:image/png;base64,' +
          btoa(fileReader.target.result);
      };
      reader.readAsBinaryString(file);
    }
  }

  onDrop($event: any): void {
    $event.preventDefault();
    const file = $event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (fileReader: any) => {
        const image = new Image();
        image.onload = () => {
          const canvas = this.fileService.compressImage(image);
          this.image = canvas.toDataURL('image/jpeg', 0.7);
          this.onImageSelected.emit(this.image);
        };
        image.src =
          'data:image/png;base64,' +
          btoa(fileReader.target.result);
      };
      reader.readAsBinaryString(file);
    }
    this.dragOver = false;
  }


  drag($event: any): void {
    $event.preventDefault();
    $event.dataTransfer.effectAllowed = 'none';
    $event.dataTransfer.dropEffect = 'none';

  }
}
