import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { httpInterceptorProviders } from 'src/app/core/interceptor/http.interceptors';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import {
  NbButtonModule,
  NbCardModule,
  NbContextMenuModule,
  NbIconModule,
  NbMenuModule
} from '@nebular/theme';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [HeaderComponent, SidebarComponent, FooterComponent],
  imports: [
    CommonModule,
    NbIconModule,
    NbContextMenuModule,
    NbMenuModule.forRoot(),
    NbButtonModule,
    RouterModule,
    NbCardModule
  ],
  providers: [httpInterceptorProviders],
  exports: [HeaderComponent, SidebarComponent]
})
export class CoreModule {}
