import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalDataSource, ServerDataSource } from 'ng2-smart-table';
import { UserService } from 'src/app/shared/service/user.service';
import { HttpClient } from '@angular/common/http';
import { DateService } from 'src/app/shared/service/date.service';
import { SmartTableService } from 'src/app/shared/service/smart-table.service';
import { DateFormatPipe } from 'src/app/shared/pipes/jdate.pipe';
import { SubSink } from 'subsink';
import { DecimalPipe } from '@angular/common';
import { UserLinkComponent } from 'src/app/shared/components/user-link/user-link.component';
import { Currency } from 'src/app/shared/models/currency.model';
import { User } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit, OnDestroy {
  tableLoading = true;
  settings = {
    actions: false,
    hideSubHeader: true,
    sort: false,
    columns: {
      currency: {
        title: 'ارز',
        sort: false,
        valuePrepareFunction: (value: any) => {
          if (value) {
            return value.name;
          } else {
            return '-';
          }
        }
      },
      user: {
        title: 'کاربر',
        sort: false,
        type: 'custom',
        valuePrepareFunction: (cell: any, row: any) => {
          return row.user;
        },
        renderComponent: UserLinkComponent
      },
      amount: {
        title: 'مقدار',
        sort: false,
        type: 'html',
        valuePrepareFunction: (value: number, row: any) => {
          return `<span dir='ltr' class='${
            value > 0 ? 'text-success' : 'text-danger'
          }'>${this.decimalPipe.transform(
            row?.currency?.id === 'IRR' ? value / 10 : value,
            row?.currency?.id === 'IRR' ? '1.0-0' : '1.0-8'
          )}</span>`;
        }
      },
      price: {
        title: 'قیمت',
        sort: false,
        type: 'html',
        valuePrepareFunction: (value: number, row: any) => {
          return `<span dir='ltr' class='${
            value > 0 ? 'text-success' : 'text-danger'
          }'>${this.decimalPipe.transform(
            row?.currency?.id === 'IRR' ? value / 10 : value,
            row?.currency?.id === 'IRR' ? '1.0-0' : '1.0-8'
          )}</span>`;
        }
      },
      balance: {
        title: 'موجودی',
        sort: false,
        type: 'html',
        valuePrepareFunction: (value: any, row: any) => {
          if (row?.currency?.id === 'IRR') {
            return `<span dir='ltr'>${this.decimalPipe.transform(value / 10, '1.0-0')}</span>`;
          }
          return `<span dir='ltr'>${this.decimalPipe.transform(value, '1.0-8')}</span>`;
        }
      },
      side: {
        title: 'نوع سفارش',
        sort: false,
        valuePrepareFunction: (value: any) => {
          if (value === 'buy') {
            return 'خرید';
          } else {
            return 'فروش';
          }
        }
      },
      description: {
        title: 'توضیحات',
        sort: false,
        valuePrepareFunction: (value: any) => {
          if (value) {
            return value;
          } else {
            return '-';
          }
        }
      },
      type: {
        title: 'وضعیت سفارش',
        sort: false,
        valuePrepareFunction: (value: any) => {
          if (value === 'trade') {
            return 'معامله';
          } else if (value === 'withdraw') {
            return 'برداشت';
          } else if (value === 'deposit') {
            return 'واریز';
          } else {
            return 'پرداخت';
          }
        }
      },
      created_at: {
        title: 'تاریخ تراکنش',
        sort: false,
        valuePrepareFunction: (value: any) => {
          return this.dateFormatPipe.transform(value, 'HH:mm jYYYY/jMM/jDD');
        }
      }
    },
    attr: {
      class: 'smart-table-responsive table-bordered text-center no-wrap'
    },
    pager: {
      display: true,
      perPage: 15
    },
    noDataMessage: 'تراکنشی یافت نشد.'
  };
  sub = new SubSink();
  recordPerPage = 15;
  page!: number;
  error: any = {};
  currencies!: Currency[];
  startDate!: string;
  endDate!: string;
  includedUsers: User[] = [];
  excludedUsers: User[] = [];
  market = 'real';
  type = 'all';
  loading = true;
  source!: ServerDataSource;

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private dateService: DateService,
    private smartTableService: SmartTableService,
    private decimalPipe: DecimalPipe,
    private dateFormatPipe: DateFormatPipe
  ) {
    this.source = new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + '/v1/admin/request',
      dataKey: 'data',
      totalKey: 'total_records',
      pagerPageKey: 'page',
      pagerLimitKey: 'limit',
      filterFieldKey: '#field#',
      sortDirKey: 'order_direction',
      sortFieldKey: 'order_by'
    });
  }

  changePage(page: any): void {
    this.source.setPage(parseInt(page, 10));
  }

  changeRecordPerPageLimit(limit: number): void {
    this.recordPerPage = limit;
    this.source.setPaging(1, limit, true);
  }

  filterTable($event: any = {}): void {
    this.startDate = $event.startDate ?? this.dateService.previousWeek();
    this.endDate = $event.endDate ?? this.dateService.todayStringGregorian();
    const filters: any = {
      startDate: this.startDate,
      endDate: this.endDate
    };
    if (this.includedUsers.length > 0) {
      filters.userIdIncluded = this.includedUsers.map((user) => user.id);
    }
    if (this.excludedUsers.length > 0) {
      filters.userIdExcluded = this.excludedUsers.map((user) => user.id);
    }
    if (this.type !== 'all') {
      filters.type = this.type;
    }
    if (this.market !== 'all') {
      filters.market = this.market;
    }
    if (this.currencies?.length > 0) {
      filters.currencyId = this.currencies.map((currency) => currency.id);
    }
  }

  ngOnInit(): void {
    this.sub.sink = this.source.onChanged().subscribe(() => {
      this.tableLoading = false;
    });
    this.sub.sink = this.smartTableService.loadingEvent.subscribe((page) => {
      if (page === 'request') {
        this.tableLoading = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
