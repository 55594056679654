export class Currency {
  id!: string;
  name!: string;
  iconPath!: string;
  withdrawFee: number;
  description: string;
  irrBuyPrice: number;
  irrSellPrice: number;
  usdcPrice: number;
  isEnabled!: boolean;
  hasTag: boolean;
  color!: string;
  price!: number;
  decimalPrecision!: number;
  demoInitAmount!: number;
  dailyWithdrawPrice!: number;
  monthlyWithdrawPrice!: number;
  minimumWithdrawAmount: number;

  constructor(input: any = {}) {
    this.id = input.id;
    this.name = input.name;
    this.description = input.description;
    this.iconPath = input.icon_path;
    this.irrBuyPrice = Math.round(input.irr_buy_price / 10 || 0);
    this.irrSellPrice = Math.round(input.irr_sell_price / 10 || 0);
    this.usdcPrice = input.usdc_price;

    this.withdrawFee = input.withdraw_fee;
    this.minimumWithdrawAmount = +input.minimum_withdraw_amount;
    this.hasTag = input.has_tag;
    this.color = input.color;
    this.price = input.price;
    this.decimalPrecision = +input.decimal_precision;
    this.demoInitAmount = +input.demo_init_amount;
    this.dailyWithdrawPrice = +input.daily_withdraw_price;
    this.monthlyWithdrawPrice = +input.monthly_withdraw_price;
    this.isEnabled = !!input.is_enabled;
    if (this.id === 'IRR') {
      this.usdcPrice = Math.round(this.usdcPrice / 10 || 0);
      this.demoInitAmount = Math.round(this.demoInitAmount / 10 || 0);
      this.dailyWithdrawPrice = Math.round(this.dailyWithdrawPrice / 10 || 0);
      this.monthlyWithdrawPrice = Math.round(this.monthlyWithdrawPrice / 10 || 0);
      this.minimumWithdrawAmount = Math.round(this.minimumWithdrawAmount / 10 || 0);
    }
  }

  rialToTooman(): void {
    if (this.id === 'IRR') {
      this.usdcPrice = this.usdcPrice * 10;
      this.demoInitAmount = this.demoInitAmount * 10;
      this.dailyWithdrawPrice = this.dailyWithdrawPrice * 10;
      this.monthlyWithdrawPrice = this.monthlyWithdrawPrice * 10;
      this.minimumWithdrawAmount = this.minimumWithdrawAmount * 10;
    }
  }
}
