<div class="row">
  <div class="col-12 col-xxl-6">
    <input
      fullWidth
      [ngModel]="emailQuery"
      (ngModelChange)="filter($event, 'email')"
      nbInput
      dir="ltr"
      type="text"
      placeholder="جستجو کاربران بر اساس ایمیل"
      [nbAutocomplete]="email"
    />

    <nb-autocomplete #email [handleDisplayFn]="viewHandleEmail.bind(this)">
      <nb-option *ngFor="let user of filterUsersByEmail$ | async" [value]="user">
        {{ user.email }}
      </nb-option>
    </nb-autocomplete>
  </div>
  <div class="col-12 mt-2 mt-xxl-0 col-xxl-6">
    <input
      fullWidth
      [ngModel]="nameQuery"
      (ngModelChange)="filter($event, 'name')"
      nbInput
      type="text"
      placeholder="جستجو کاربران بر اساس نام"
      [nbAutocomplete]="name"
    />

    <nb-autocomplete #name [handleDisplayFn]="viewHandleName.bind(this)">
      <nb-option *ngFor="let user of filterUsersByName$ | async" [value]="user">
        <ng-container *ngIf="user.firstName && user.lastName; else showEmail">
          {{ user.firstName + ' ' + user.lastName }}
        </ng-container>
        <ng-template #showEmail>
          {{ user.email }}
        </ng-template>
      </nb-option>
    </nb-autocomplete>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12 h-100">
    <nb-card class="custom-card mb-0 h-100">
      <nb-card-body>
        <nb-tag-list *ngIf="selectedUsers.length > 0" (tagRemove)="onTagRemove($event)">
          <nb-tag
            *ngFor="let user of selectedUsers"
            [text]="
              user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : user.email
            "
            [attr.data-delete]="user.id"
            removable
          ></nb-tag>
        </nb-tag-list>
        <div *ngIf="selectedUsers.length == 0">
          <h6 class="mb-0">هیچ کاربری انتخاب نشده است</h6>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>
