import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from 'src/app/shared/models/user.model';
import { HttpService } from 'src/app/shared/service/http.service';
import { Card } from 'src/app/shared/models/card.model';
import { map } from 'rxjs/operators';
import { Iban } from 'src/app/shared/models/iban.model';
import { Province } from 'src/app/shared/models/province';
import { City } from 'src/app/shared/models/city';
import { Address } from 'src/app/shared/models/address';
import { WithdrawModel } from 'src/app/shared/models/withdraw.model';
import { Wallet } from 'src/app/shared/models/wallet.model';
import { UserAssetModel } from 'src/app/shared/models/user-asset.model';
import { Role } from 'src/app/shared/models/role.model';
import { Admin } from 'src/app/shared/models/admin.model';
import {CurrencyFaqModel, FaqModel} from 'src/app/shared/models/faq.model';
import {FaqCategory} from '../models/faq-category.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userStream = new BehaviorSubject<User>(new User());
  currentUserPage!: number;
  setUserListPage = new BehaviorSubject<number>(1);

  constructor(private http: HttpService) {
  }

  verifyUserIdentification(state: 'ACCEPTED' | 'REJECTED', id: number): Observable<any> {
    return this.http.request('put', `/v1/admin/user/authentication/${id}`, {
      state
    });
  }
  getImage(filePath: string): Observable<any> {
    return this.http.request('getBlob', `/v1/admin/storage/private/${filePath}`);
  }
  getUserList(filters: any = {}): Observable<User[]> {
    const searchParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => searchParams.append(key, filters[key]));
    return this.http.request('get', `/v1/admin/user?${searchParams.toString()}`).pipe(
      map((response: any) => response.data),
      map((users: any[]) => users.map((user) => new User(user)))
    );
  }


  getFaqOfCurrency(id: string): Observable<CurrencyFaqModel[]> {
    return this.http
      .request('get', `/v1/admin/currency/${id}/faq`)
      .pipe(map((response: any) => response.data));
  }

  getAllFaqCategory(): Observable<FaqCategory[]> {
    return this.http
      .request('get', `/v1/admin/faq/category`)
      .pipe(map((response: any) => response.data));
  }

  getAllFaq(page: string): Observable<FaqModel[]> {
    return this.http
      .request('get', `/v1/admin/faq?page=${page}`)
      .pipe(map((response: any) => response.data));
  }

  //
  // saveFaq(faq: CurrencyFaqModel, currencyId: string): Observable<any> {
  //   if (faq.id) {
  //     return this.http.request('put', `/v1/admin/currency/faq/${faq.id}`, faq);
  //   } else {
  //     return this.http.request('post', `/v1/admin/currency/${currencyId}/faq`, faq);
  //   }
  // }
  saveFaq(faq: FaqModel): Observable<any> {
    const faqBody = {name: faq.name, body: faq.body, category_id: faq.category.id};
    if (faq.id) {
      return this.http.request('put', `/v1/admin/faq/${faq.id}`, faqBody);
    } else {
      return this.http.request('post', `/v1/admin/faq`, faqBody);
    }
  }
  //
  // deleteFaq(faq: CurrencyFaqModel): Observable<any> {
  //   return this.http.request('delete', `/v1/admin/currency/faq/${faq.id}`);
  // }
  deleteFaq(faq: FaqModel): Observable<any> {
    return this.http.request('delete', `/v1/admin/faq/${faq.id}`);
  }


}
