import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appOnlyNumber]',
  providers: [NgModel]
})
export class OnlyNumberDirective {
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef, private model: NgModel) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('input', ['$event']) onchange(event: any): void {
    const value = this.el.value?.toString();
    this.el.value = value.replace(/\D/g, '');
  }
}
