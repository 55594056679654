import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import * as moment from 'moment-jalaali';
import { DateModel } from 'src/app/shared/models/date.model';
import { DateService } from 'src/app/shared/service/date.service';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent implements OnInit {
  @Input() set setDay(input: number) {
    this.day = input;
  }

  @Input() set setMonth(input: number) {
    this.month = input;
  }

  @Input() set setYear(input: number) {
    this.year = input;
  }

  @Input() now = false;

  @Input() minYear = 1390;
  @Input() maxYear = +moment().format('jYYYY');
  @Input() disableHistorySelection = false;
  @Input() error = false;
  day!: number;
  month!: number;
  year!: number;
  days = new Array(31);

  currentYear = +moment().format('jYYYY');
  currentMonth = +moment().format('jMM');
  currentDay = +moment().format('jDD');
  months = [
    'فروردین',
    'اردیبهشت',
    'خرداد',
    'تیر',
    'مرداد',
    'شهریور',
    'مهر',
    'آبان',
    'آذر',
    'دی',
    'بهمن',
    'اسفند'
  ];
  years: number[] = [];

  @Output() dateSelected: EventEmitter<DateModel> = new EventEmitter<DateModel>();

  constructor(private dateService: DateService, private cf: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.now) {
      this.day = +moment().format('jDD');
      this.month = +moment().format('jMM');
      this.year = +moment().format('jYYYY');
    }
    this.years = new Array(this.maxYear - this.minYear + 1);
    this.years = [...this.years].map((val, i) => i + this.minYear);
  }

  checkDate(value?: number, type?: string): void {
    if (type === 'year') {
      if (this.checkDisableDay(this.day, this.month, value)) {
        this.day = this.currentDay;
        this.cf.markForCheck();
      }
      if (this.checkDisableMonth(this.month, value)) {
        this.month = this.currentMonth;
        this.cf.markForCheck();
      }
    }
    if (type === 'month') {
      if (this.checkDisableDay(this.day, value)) {
        this.day = this.currentDay;
        this.cf.markForCheck();
      }
    }
    const date = {} as DateModel;
    date.year = type === 'year' ? value : this.year;
    date.month = type === 'month' ? value : this.month;
    date.day = type === 'day' ? value : this.day;
    if (this.dateService.dateValidate(date)) {
      this.dateSelected.emit(date);
    }
  }

  checkDisableDay(day: number, month = this.month, year = this.year): boolean {
    if (this.disableHistorySelection) {
      if (year < this.currentYear) {
        return true;
      } else if (year === this.currentYear) {
        if (month < this.currentMonth) {
          return true;
        } else if (month === this.currentMonth && day < this.currentDay) {
          return true;
        }
      }
    }
    return false;
  }

  checkDisableMonth(month: number, year = this.year): boolean {
    if (this.disableHistorySelection) {
      if (year < this.currentYear) {
        return true;
      } else if (year === this.currentYear) {
        if (month < this.currentMonth) {
          return true;
        }
      }
    }
    return false;
  }

  checkDisableYear(year: number): boolean {
    return this.disableHistorySelection && year < this.currentYear;
  }

  isLeapYear(): boolean {
    if (this.year) {
      return moment.jIsLeapYear(this.year);
    } else {
      return false;
    }
  }
}
