import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Modal} from 'bootstrap';
import {ModalService} from '../../service/modal.service';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewInit {

  modal!: Modal;
  @Input() maxWidth = 734;
  id!: string;

  constructor(private modalService: ModalService) {
    this.id = this.makeId(16);
  }


  makeId(length: any): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    this.modal = new Modal(document.getElementById(this.id));
    this.modalService.modalStream.next(this.modal);
  }

  ngOnInit(): void {


  }

  ngOnDestroy(): void {
    // @ts-ignore
    this.modalService.modalStream.next(null);
    this.modal?.dispose();
  }

}
