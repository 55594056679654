import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SmartTableService } from 'src/app/shared/service/smart-table.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class SmartTableLoadingInterceptor implements HttpInterceptor {
  constructor(private smartTableService: SmartTableService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      request.url.includes(environment.baseUrl + '/v1/admin/user') &&
      request.method.toLowerCase() === 'get'
    ) {
      this.smartTableService.loadingEvent.next('user');
    }

    if (
      request.url.includes(environment.baseUrl + '/v1/admin/request') &&
      request.method.toLowerCase() === 'get'
    ) {
      this.smartTableService.loadingEvent.next('request');
    }
    return next.handle(request);
  }
}
