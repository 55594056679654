import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpError } from '../../shared/models/http-error.model';
import { Utility } from './utility';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlingInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  handleError(error: HttpErrorResponse): Observable<HttpEvent<any>> {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
    const httpError = new HttpError();
    httpError.status = error.status;
    if (httpError.status === 401) {
      this.authenticationService.signOut();
      this.router.navigateByUrl('/sign-in');
    } else if (httpError.status === 422) {
      httpError.validationError = Utility.camelize(error.error.errors);
    } else {
      httpError.error = error.error.message ?? 'خطایی در سامانه رخ داده است.';
    }
    if (
      httpError.status === 403 &&
      error.error?.status === 'GOOGLE_AUTH_REQUIRED' &&
      this.authenticationService.getToken() !== undefined
    ) {
      Toast.fire({ icon: 'error', text: error.error?.message });
      this.router.navigateByUrl('/dashboard/two-factor-authentication');
    }
    return throwError(httpError);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(this.handleError.bind(this)));
  }
}
