import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-jalaali';

@Pipe({
  name: 'fixMonthDays'
})
export class FixMonthDaysPipe implements PipeTransform {
  transform(days: number[], ...args: number[]): number[] {
    if (args[0] <= 6 || !args[0]) {
      return days;
    } else if (!args[1] || moment.jIsLeapYear(args[1])) {
      return days.slice(0, 30);
    } else {
      return days.slice(0, 29);
    }
  }
}
