import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { SubSink } from 'subsink';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { Router } from '@angular/router';
import { MenuItemScope } from 'src/app/shared/models/menu-item-scope.model';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  sub = new SubSink();

  constructor(
    private menuService: NbMenuService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) {
    this.sub.sink = menuService.onItemClick().subscribe(($event: any) => {
      if ($event.item.data === 'exit') {
        this.authenticationService.signOut();
        this.router.navigateByUrl('/sign-in');
      }
    });
  }

  menu: MenuItemScope[] = [
    {
      title: 'لیست کاربران',
      link: '/dashboard/users',
      icon: {
        pack: 'font-awesome-light',
        icon: 'users'
      }
    },
    {
      title: 'احراز هویت',
      link: '/dashboard/identification',
      icon: {
        pack: 'font-awesome-light',
        icon: 'fingerprint'
      }
    },
    {
      title: 'سفارشات کاربران',
      link: '/dashboard/orders',
      icon: {
        pack: 'font-awesome-light',
        icon: 'exchange-alt'
      }
    },
    {
      title: 'بحث',
      link: '/dashboard/forum',
      icon: {
        pack: 'font-awesome-light',
        icon: 'file-medical'
      },
      children: [
        {
          title: 'دسته‌بندی',
          link: '/dashboard/forum/category'
        },
        {
          title: 'لیست بحث ها',
          link: '/dashboard/forum',
        }
      ]
    },
    // {
    //   title: 'کامنت‌ها',
    //   link: '/dashboard/comments',
    //   icon: {
    //     pack: 'font-awesome-light',
    //     icon: 'comments'
    //   }
    // },
    {
      title: 'سوالات متداول',
      icon: {
        pack: 'font-awesome-light',
        icon: 'question'
      },
      children: [
        {
          title: 'دسته‌بندی',
          link: '/dashboard/faq/category'
        },
        {
          title: 'سوالات',
          link: '/dashboard/faq/questions'
        }
      ]
    },

    {
      title: 'خروج',
      icon: {
        pack: 'font-awesome-light',
        icon: 'sign-out'
      },
      data: 'exit'
    }
  ];

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
