import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    <div>
      <span >{{ renderValue }}</span>
    </div>
  `
})
export class UserLinkComponent implements ViewCell, OnInit {
  renderValue = '';
  link = '';
  @Input() value: any | number = {};
  @Input() rowData: any;

  constructor() {}

  ngOnInit(): void {
    if (this.value && (this.value.first_name || this.value.last_name)) {
      this.renderValue = `${this.value.first_name} ${this.value.last_name}`;
      this.link = '/dashboard/user/' + this.value.id;
    } else {
      this.renderValue = '-';
    }
  }
}
