<nb-card>
  <nb-card-header>
    <div class="d-flex align-items-center">
      <i class="fal fa-users ms-1"></i>
      لیست کاربران
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <nb-select
        class="ms-2"
        [selected]="15"
        (selectedChange)="changeRecordPerPageLimit($event)"
        placeholder="تعداد نمایش"
      >
        <nb-option [value]="10">10</nb-option>
        <nb-option [value]="15">15</nb-option>
        <nb-option [value]="25">25</nb-option>
        <nb-option [value]="50">50</nb-option>
        <nb-option [value]="100">100</nb-option>
      </nb-select>
    </div>
    <div class="position-relative">
      <ng2-smart-table
        [settings]="settings"
        [source]="source"
        (custom)="actions($event)"
      ></ng2-smart-table>
      <div
        *ngIf="source.count() > recordPerPage"
        class="d-flex align-items-center pagination-input"
        dir="ltr"
      >
        <form class="d-flex" (ngSubmit)="changePage(page)">
          <button
            type="submit"
            class="go-to-page-button"
            nbButton
            status="primary"
            [disabled]="!page"
          >
            برو به
          </button>
          <input class="go-to-page-input" type="number" nbInput name="gotTo" [(ngModel)]="page" />
        </form>
      </div>
    </div>
    <div *ngIf="tableLoading" class="loading-backdrop">
      <i class="fal fa-circle-notch fa-spin fa-3x"></i>
    </div>
  </nb-card-body>
</nb-card>
