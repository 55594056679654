import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appNumberLimit]',
  providers: [NgModel]
})
export class NumberLimitDirective {
  @Input() maxDigit = 9;
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef, private model: NgModel) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('input', ['$event']) onchange(event: any): void {
    let value = this.el.value;
    value = this.el.value?.toString();
    if (value && value.length > this.maxDigit) {
      this.el.value = value.substr(0, value.length - 1);
    }
  }
}
