<nb-layout>
  <nb-layout-header fixed>
    <app-header></app-header>
  </nb-layout-header>
  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>
  <nb-sidebar
    (responsiveStateChange)="onSidebarStateChange($event)"
    [responsive]="true"
    [right]="true"
    start
  >
    <app-sidebar></app-sidebar>
  </nb-sidebar>
</nb-layout>
