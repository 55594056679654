import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NbDialogService } from '@nebular/theme';
import { ViewCell } from 'ng2-smart-table';
import Viewer from 'viewerjs';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageModalComponent implements  ViewCell, OnDestroy {
  @ViewChild('dialog') dialog!: TemplateRef<any>;
  @ViewChild('image') image!: ElementRef;

  @Input() set value(data: any) {
    this.data = data;
    this.userService.getImage(this.data.img).subscribe(data=> {
      const unsafeImageUrl = URL.createObjectURL(data); 
      this.data.img= this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
    }) 
  }

  @Input() rowData: any;
  data: any;
  viewImage = false;
  viewer!: Viewer;

  constructor(private dialogService: NbDialogService,private userService:UserService, private sanitizer: DomSanitizer) {}

  showImages(): void {
    this.viewImage = true;
    setTimeout(() => {
      this.viewer = new Viewer(this.image.nativeElement, {
        inline: false,
        zoomRatio: 0.3,
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          reset: false,
          prev: false,
          play: false,
          next: false,
          rotateLeft: true,
          rotateRight: true,
          flipHorizontal: true,
          flipVertical: true
        }
      });
      this.image.nativeElement.click();
    });
  }


  ngOnDestroy(): void {
    this.viewer?.destroy();
  }
}
