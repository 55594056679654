export class HttpError {
  status: number;
  error: string;
  validationError: {};

  constructor(error: any = {}) {
    this.status = error.status;
    this.error = error.error;
    this.validationError = error.validationError;
  }
}
