import { Component, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/shared/service/sidebar.service';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-feature-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {
  constructor(private sidebarService: SidebarService, private userService: UserService) {}

  onSidebarStateChange(resState: 'pc' | 'tablet' | 'mobile'): void {
    this.sidebarService.getResponsiveState.next(resState);
  }

  ngOnInit(): void {}
}
