import { Utility } from 'src/app/core/interceptor/utility';
import {Avatar} from './avatar.model';

export class User {
  id: number;
  firstName: string;
  lastName: string;
  name: string;
  username: string;
  password: string;
  email: string;
  phone: string;
  landlinePhone: string;
  gender: 'male' | 'female';
  nationalId: number;
  nationalIdImage: string;
  provinceId: number;
  cityId: number | undefined;
  address: string;
  homeBill?: string;
  postalCode: string;
  birthday: string | undefined;
  identityImage?: string;
  nationalCardImage: string;
  feePercent: number | null;
  scopes: string[];
  addressVerified: 'NOT_STARTED' | 'PENDING' | 'ACCEPTED' | 'REJECTED' = 'NOT_STARTED';
  bankVerified: 'NOT_STARTED' | 'PENDING' | 'ACCEPTED' | 'REJECTED';
  emailVerified: 'NOT_STARTED' | 'PENDING' | 'ACCEPTED' | 'REJECTED';
  identityCardVerified: 'NOT_STARTED' | 'PENDING' | 'ACCEPTED' | 'REJECTED';
  identityVerified: 'NOT_STARTED' | 'PENDING' | 'ACCEPTED' | 'REJECTED';
  phoneVerified: 'NOT_STARTED' | 'PENDING' | 'ACCEPTED' | 'REJECTED';
  landlinePhoneVerified: 'NOT_STARTED' | 'PENDING' | 'ACCEPTED' | 'REJECTED';
  defaultTransactionFee: number;
  active: boolean;
  authenticated: boolean;
  avatar!: Avatar;
  createdAt = '';

  constructor(input: any = {}) {
    this.id = input.id;
    this.authenticated = input.authenticated;
    this.createdAt = input.created_at;
    this.firstName = input.first_name;
    this.lastName = input.last_name;
    this.username = input.username;
    this.password = input.password;
    this.email = input.email;
    this.phone = input.phone;
    this.landlinePhone = input.landline_phone;
    this.gender = input.gender;
    this.nationalId = input.national_id;
    this.provinceId = input.province_id;
    this.cityId = input.city_id;
    this.homeBill = input.home_bill_path;
    this.identityImage = input.identity_path;
    this.nationalCardImage = input.national_id_path;
    this.birthday = input.birthday;
    this.address = input.address;
    this.postalCode = input.postal_code;
    this.name = input.name;
    this.nationalIdImage = input.national_id_image;
    this.feePercent = input.fee_percent === null ? null : +input.fee_percent;
    this.defaultTransactionFee = +input.default_transaction_percent;
    this.addressVerified = input.address_verified ?? 'NOT_STARTED';
    this.bankVerified = input.bank_verified ?? 'NOT_STARTED';
    this.emailVerified = input.email_verified ?? 'NOT_STARTED';
    this.addressVerified = input.address_verified ?? 'NOT_STARTED';
    this.identityVerified = input.identity_verified ?? 'NOT_STARTED';
    this.identityCardVerified = input.identity_card_verified ?? 'NOT_STARTED';
    this.phoneVerified = input.phone_verified ?? 'NOT_STARTED';
    this.landlinePhoneVerified = input.landline_phone_verified ?? 'NOT_STARTED';
    this.scopes = input.scopes;
    this.active = input.active;
    Object.assign(this, Utility.camelize(input));
    if (this.feePercent === null) {
      this.feePercent = this.defaultTransactionFee;
    }
  }
}
