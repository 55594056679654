import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';

import { FeatureRoutingModule } from './feature-routing.module';
import { RootComponent } from './components/root/root.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { FormsModule } from '@angular/forms';
import {
  NbAccordionModule,
  NbCardModule,
  NbIconModule,
  NbLayoutModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbToggleModule
} from '@nebular/theme';
import { RecaptchaModule } from 'ng-recaptcha';
import { CoreModule } from 'src/app/core/core.module';
import { UsersComponent } from './components/users/users.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { DateFormatPipe } from '../shared/pipes/jdate.pipe';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { NgChartsModule } from 'ng2-charts';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [
    RootComponent,
    AuthenticationComponent,
    UsersComponent,
    TransactionsComponent,
  ],
  imports: [
    FeatureRoutingModule,
    SharedModule,
    FormsModule,
    Ng2SmartTableModule,
    NbCardModule,
    NbLayoutModule,
    RecaptchaModule,
    NbSidebarModule.forRoot(),
    CoreModule,
    NbSpinnerModule,
    NbTabsetModule,
    NgChartsModule,
    NbToggleModule,
    NbIconModule,
    NbAccordionModule,
    NgxSkeletonLoaderModule
  ],
  providers: [DateFormatPipe, DecimalPipe]
})
export class FeatureModule {}
