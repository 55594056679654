import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-jalaali';

moment.loadPersian();

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return moment.utc(value, 'YYYY-MM-DD HH:mm').local().format(args[0]);
  }
}
