import { Pipe, PipeTransform } from '@angular/core';
import { format, register } from 'timeago.js';
import fa from 'timeago.js/esm/lang/fa';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: string, ...args: string[]): unknown {
    if (value) {
      // returned from mysql timestamp/datetime field
      const formatedDate = new Date(Date.parse(value.replace(/-/g, '/')));
      register('fa_IR', fa);
      return format(formatedDate, args[0] ?? 'en_US');
    } else {
      return '';
    }
  }
}
