import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { HttpError } from 'src/app/shared/models/http-error.model';
import { User } from 'src/app/shared/models/user.model';
import { RecaptchaComponent } from 'ng-recaptcha';
import { UserService } from 'src/app/shared/service/user.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  error: any = {};
  signInLoading = false;
  user: User = new User();
  otpLoading = false;
  errorMessage: string | undefined;
  showPassword = false;
  showConfirmPassword = false;
  @ViewChild('captchaRef') recaptcha!: RecaptchaComponent;
  rememberMe = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {
  }

  getInputType(): string {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }

  toggleShowPassword(type: string): void {
    if (type === 'password') {
      this.showPassword = !this.showPassword;
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }

  signIn(): void {
    this.errorMessage = undefined;
    this.error = {};
    this.signInLoading = true;
    this.authenticationService
      .signIn(this.user.phone, this.user.password)
      .subscribe(
        (response) => {
          if (response.status === 'OK') {
            if (response.data.role === 'admin') {
              this.userService.userStream.next(new User(response.data));
              this.authenticationService.setToken(response.token, this.rememberMe);
              this.authenticationService.userRole = response.data.role;
              this.router.navigateByUrl('/dashboard/user');
            } else {
              this.errorMessage = 'نام‌کاربری یا رمزعبور اشتباه است.';
            }

          }
        },
        (response: HttpError) => {
          if (response.status === 422) {
            this.error = response.validationError;
          } else {
            this.errorMessage = response.error;
          }
          this.signInLoading = false;
        }
      );
  }


  ngOnInit(): void {
  }
}
