import {Injectable} from '@angular/core';
import {Modal} from 'bootstrap';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  // @ts-ignore
  modalStream = new BehaviorSubject<Modal>(null);

  constructor() {
  }
}
