<div class="row">
  <div class="col-12 col-sm-5">
    <label class="d-flex mb-2">تاریخ شروع</label>
  </div>
  <div class="col-12 col-sm-5">
    <label class="d-flex mb-2">تاریخ پایان</label>
  </div>
</div>
<div class="row align-items-center mb-3">
  <div class="col-12 col-sm-5">
    <app-datepicker
      (dateSelected)="setStart($event)"
      [minYear]="1398"
      [setYear]="jalaliStartDate.year || 0"
      [setMonth]="jalaliStartDate.month || 0"
      [setDay]="jalaliStartDate.day || 0"
    ></app-datepicker>
  </div>
  <div class="col-12 col-sm-5">
    <app-datepicker
      (dateSelected)="setEnd($event)"
      [minYear]="1398"
      [setYear]="jalaliEndDate.year || 0"
      [setMonth]="jalaliEndDate.month || 0"
      [setDay]="jalaliEndDate.day || 0"
    ></app-datepicker>
  </div>
  <div class="col-12 col-sm-2">
    <button
      [disabled]="loading"
      [nbSpinner]="loading"
      class="btn"
      size="medium"
      nbButton
      status="primary"
      (click)="getDates()"
    >
      <i class="fal fa-search"></i>
    </button>
  </div>
</div>
<app-validation-error [errors]="errors" name="finishAt"></app-validation-error>
<app-validation-error [errors]="errors" name="startAt"></app-validation-error>
